import React, {Fragment, ReactNode} from "react";
import shopApi from "../api/shop";
import {IProduct, TranslationsType} from "../types/homePageTypes";
import {GetServerSideProps, NextPage} from "next";
import Slider from "../components/ForCWV/Slider";
import Heading from "../components/ForCWV/Heading";
import Head from "next/head";
import MainLayout, {ICurrencies, ILayout, ILocales} from "../components/ForCWV/Wrappers/MainLayout";
import {HomePageTranslations} from "../services/translationKeys";
import {layoutProps} from "../services/layoutProps";
import {domainUrl} from "../helper";
import BuilderSection from "../components/builder/BuilderDataRender";
import NewProducts from "../components/ForCWV/Products/BlockProduct/NewProducts";
import FeaturedProducts from "../components/ForCWV/Products/BlockProduct/FeaturedProducts";
import ThirdProducts from "../components/ForCWV/Products/BlockProduct/ThirdProducts";

interface IHomePage extends ILayout {
    requestLogo: string;
    dbName: string;
    domain: string;
    sliderData: {
        sliders: Array<{
            path: string;
            title: string;
            content: string | TrustedHTML;
            settings: Record<`${number}`, { priority?: boolean; loading?: "eager" | "lazy" }>
            slider_path: string
        }>
    };
    newProducts: Array<IProduct>;
    featuredProducts: Array<IProduct>;
    thirdSectionProducts: Array<IProduct>;
    metas: {
        meta_description: string;
        meta_keywords: string;
        meta_title: string;
        share_pic: string;
    };
    translationsData: TranslationsType;
    backOrderValue: string;
    currencies: Array<ICurrencies>;
    selectedLocale: string;
    getBuilderData: any;
    showBuilderData: boolean;
    isMobile: boolean;
    showSlider: "Slider" | "Empty";
    showThirdSection: "ThirdSection" | "Empty"
}

const Home: NextPage<IHomePage> = (pageProps) => {
    const {
        sliderData,
        newProducts,
        featuredProducts,
        thirdSectionProducts,
        metas: {
            meta_title = "",
            meta_description = "",
            meta_keywords = "",
            share_pic = "",
        },
        dbName,
        domain,
        translationsData,
        backOrderValue,
        currencies = [],
        selectedLocale = "",
        getBuilderData,
        showBuilderData,
        isMobile,
        showSlider,
        showThirdSection
    } = pageProps
    const builderProps = {
        getBuilderData,
        domain,
        dbName,
        translationsData,
        selectedLocale,
        currencies,
        backOrderValue
    }

    const [{
        code: selectedCurrCode = "",
        symbol: selectedCurrSymbol = "",
        exchange_rate: {rate: selectedCurrRate = "1"} = {},
    } = {}] = currencies

    const showBuilderComponents:
        (id: number) => Record<"true" | "false", ReactNode> = (id) => {
        return {
            "true": <BuilderSection tab={id} {...builderProps} />,
            "false": null
        }
    }
    const productProps = {
        selectedLocale,
        dbName,
        translationsData,
        backOrderValue,
        selectedCurrItems: {selectedCurrCode, selectedCurrRate, selectedCurrSymbol},
        isMobile
    }

    const ShowThirdSection = {
        "ThirdSection": <Fragment>
            <ThirdProducts products={thirdSectionProducts} {...productProps} />
            {showBuilderComponents(4)[`${showBuilderData}`]}
        </Fragment>,
        "Empty": null
    }
    const ShowSlider = {
        "Slider": <Slider slider={sliderData} dbName={dbName} translationsData={translationsData}/>,
        "Empty": null
    }

    return (
        <MainLayout {...pageProps} >
            <Fragment>
                <Head>
                    <title>{meta_title}</title>
                    <meta name="title" content={meta_title}/>
                    <meta name="description" content={meta_description}/>
                    <meta name="keywords" content={meta_keywords}/>
                    <meta name="generator" content="Zegashop.com"/>
                    <meta name="twitter:title" content={meta_title}/>
                    <meta name="twitter:description" content={meta_description}/>
                    <meta name="twitter:keywords" content={meta_keywords}/>
                    <meta name="twitter:image" content={domainUrl(`${dbName}/storage/${domain}${share_pic}`)}/>
                    <meta property="og:title" content={meta_title}/>
                    <meta property="og:description" content={meta_description}/>
                    <meta property="og:keywords" content={meta_keywords}/>
                    <meta property="og:image" content={domainUrl(`${dbName}/storage/${domain}${share_pic}`)}/>
                    <meta property="og:url" content={domainUrl(`${dbName}/${selectedLocale}`)}/>
                    <meta property="og:locale" content={selectedLocale}/>
                    <link rel="canonical" href={domainUrl(`${dbName}/${selectedLocale}`)}/>
                </Head>
                {ShowSlider[showSlider]}
                <Heading translationsData={translationsData}/>
                {showBuilderComponents(1)[`${showBuilderData}`]}
                <NewProducts products={newProducts} {...productProps} />
                {showBuilderComponents(2)[`${showBuilderData}`]}
                <FeaturedProducts products={featuredProducts} {...productProps} />
                {showBuilderComponents(3)[`${showBuilderData}`]}
                {ShowThirdSection[showThirdSection]}
            </Fragment>
        </MainLayout>
    );
};

export const getServerSideProps: GetServerSideProps = async ({locale, req, params}: any) => {
    const [{
        currencies_new = [],
        locales = [],
        default_locale_id = 1
    }] = await shopApi.getChannelInfo(req.m, req.headers, req.defaultDB.db_name)
    const {code: defaultLangCode = "en"} = locales.find(({id: langId}: ILocales) => langId === default_locale_id) || {}
    const selectedLocale = locale !== "catchAll" ? locale : defaultLangCode;
    const {locale_image: selectedLocaleImg, name: selectedLocaleName} = locales.find((lang: ILocales) => lang?.code === selectedLocale) || {}
    const filteredLocale = locales.filter((lang: ILocales) => lang?.code !== selectedLocale)
    const metaTags = await shopApi.getHomeMetaAsync(selectedLocale, req.m) || {};
    const userAgent = req.headers['user-agent'];
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobile = mobileRegex.test(userAgent)
    const {featuredProducts, newProducts, thirdSectionProducts} = await shopApi.getHomeProductsAsync(
        {locale: selectedLocale, selectedRate: currencies_new[0], isMobile}, req.m
    );

    const sliderData = await shopApi.sliderData(selectedLocale, req.m, isMobile);
    const translationsData = await shopApi.GetTranslations(selectedLocale, HomePageTranslations, req.m) || {};
    const getBuilderData = await shopApi.getBuilderDataAsync(
        2, selectedLocale, req.m, req.headers["x-forwarded-host"], req.defaultDB.db_name, currencies_new[0]
    ) || null

    const wrapperProps = await layoutProps({
        requestHeadersDB: req.headers["x-forwarded-host"],
        requestDbName: req.defaultDB.db_name,
        requestModels: req.m,
        selectedLocale: selectedLocale,
        footerTrackItl: translationsData,
        requestLogo: req.assets.logo_image
    })
    return {
        props: {
            requestLogo: req.assets.logo_image,
            sliderData,
            metas: metaTags,
            newProducts,
            showSlider: !!sliderData.sliders.length ? "Slider" : "Empty",
            showThirdSection: !!thirdSectionProducts.length ? "ThirdSection" : "Empty",
            featuredProducts,
            thirdSectionProducts,
            selectedLocaleImg,
            selectedLocaleName,
            translationsData: translationsData,
            locales: filteredLocale,
            currencies: currencies_new,
            selectedRate: currencies_new[0],
            activeMobileNavElement: 0,
            getBuilderData,
            showBuilderData: Object.values(getBuilderData).some((lll) => (lll as Array<any>).length > 0),
            isMobile,
            ...wrapperProps
        },
    };
}

export default Home;